<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <span class="heading">Rescuer Details</span>

              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card flat>
                      <v-layout wrap justify-center pa-2>
                        <v-flex xs12 sm2 lg2 text-center align-self-center>
                          <v-img contain height="100%" width="90%" :src="mediaURL + rescueruserdata.photo" fill-height
                            @click="dialog = true" class="clickable"></v-img>
                        </v-flex>
                        <v-flex xs12 sm10 lg10>
                          <v-layout wrap justify-start>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescueruserdata.name">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Name</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescueruserdata.name }}
                                  <span v-if="!rescueruserdata.name">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescueruserdata.username">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Username</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescueruserdata.username }}
                                  <span v-if="!rescueruserdata.username">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescueruserdata.phone">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Phone</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescueruserdata.phone }}
                                  <span v-if="!rescueruserdata.phone">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescueruserdata.organization">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Organization</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescueruserdata.organization }}
                                  <span v-if="!rescueruserdata.organization">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.education">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Education</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.education }}
                                  <span v-if="!rescuerdata.education">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.employment">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Employment</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.employment }}
                                  <span v-if="!rescuerdata.employment">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.adharnumber">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Aadhar Number</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.adharnumber }}
                                  <span v-if="!rescuerdata.adharnumber">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescueruserdata.email">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Email</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescueruserdata.email }}
                                  <span v-if="!rescueruserdata.email">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescueruserdata.dob">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Date of Birth</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ formatDate(rescueruserdata.dob) }}
                                  <span v-if="!rescueruserdata.dob">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.bloodGroup">
                              <v-flex xs12 text-left>
                                <span class="itemKey">BloodGroup</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.bloodGroup }}
                                  <span v-if="!rescuerdata.bloodGroup">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.permanentAddress">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Permanent Address</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.permanentAddress }}
                                  <span v-if="!rescuerdata.permanentAddress">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.presentAddress">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Present Address</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.presentAddress }}
                                  <span v-if="!rescuerdata.presentAddress">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.name">
                              <v-flex xs12 text-left>
                                <span class="itemKey">District</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.district }}
                                  <span v-if="!rescuerdata.district">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.districtCoordinator">
                              <v-flex xs12 text-left>
                                <span class="itemKey">District Coordinator</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue" v-if="rescuerdata.districtCoordinator == true">
                                  Yes
                                  <span v-if="
                                    rescuerdata.districtCoordinator == false
                                  ">No</span>
                                </span>
                                <span v-if="!rescuerdata.districtCoordinator">-</span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.offence">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Any Offense History</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue" v-if="rescuerdata.offence == true">
                                  Yes
                                  <span v-if="rescuerdata.offence == false">No</span>
                                </span>
                                <span v-if="!rescuerdata.offence">-</span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="
                              rescuerdata.offenceDetails &&
                              rescuerdata.offence === true
                            ">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Offense Details</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.offenceDetails }}
                                  <span v-if="!rescuerdata.offenceDetails">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Rescue Started At</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.rescueStartedAt }}
                                  <span v-if="!rescuerdata.rescueStartedAt">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Applied Date</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ formatDate(rescuerdata.applieddate) }}
                                  <span v-if="!rescuerdata.applieddate">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata.diseaseStatus">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Any Disease</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue" v-if="rescuerdata.diseaseStatus == true">
                                  Yes
                                  <span v-if="rescuerdata.diseaseStatus == false">No</span>
                                </span>
                                <span v-if="!rescuerdata.diseaseStatus">-</span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2 v-if="
                              rescuerdata.diseaseDetails &&
                              rescuerdata.diseaseStatus
                            ">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Disease</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.diseaseDetails }}
                                  <span v-if="!rescuerdata.diseaseDetails">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                           

                            <v-flex xs12 sm6 lg4 md4
                              v-if="(mainRole === 'division' || mainRole === 'circle') && rescuerdata.challan">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Challan</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span v-if="
                                  rescuerdata.challan &&
                                  rescuerdata.challan.split('.').pop() == 'pdf'">
                                  <a target="_blank" :href="mediaURL + rescuerdata.challan">
                                    <v-btn style="border-radius: 14px; border: 0.5px solid #707070" px-5 pt-0 text small
                                      outlined>
                                      View Challan
                                    </v-btn>
                                  </a>
                                </span>
                                <span v-else>
                                  <v-btn @click="(doc = rescuerdata.challan), (docDialog = true)"
                                    style="border-radius: 14px; border: 0.5px solid #707070" px-5 pt-0 text small
                                    outlined>
                                    View Challan
                                  </v-btn>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 md4 pt-2 v-if="rescuerdata.adhar">
                              <v-layout wrap justify-start>
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Aadhar Image</span>
                                </v-flex>
                                <v-flex xs12 sm2 text-left>
                                  <v-img contain height="100%" width="100%" :src="mediaURL + rescuerdata.adhar"
                                    fill-height @click="dialogadhar = true" class="clickable"></v-img>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2
                              v-if="(mainRole === 'division' || mainRole === 'circle') && rescuerdata.recommendations">
                              <v-flex xs12 text-left>
                                <span class="itemKey">Recommendations</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ rescuerdata.recommendations }}
                                  <span v-if="!rescuerdata.recommendations">---</span>
                                </span>
                              </v-flex>
                            </v-flex>

                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-layout wrap justify-start pt-10 v-if="mainRole === 'division'  && rescuerdata.challan && !rescuerdata.recommendations">
                <v-flex xs12>
                  <span class="heading">Recommenations For Rescuer</span>
                </v-flex>
                <v-flex xs12 pt-2 class="subheading">

                  <v-textarea outlined v-model="recommendations" dense placeholder="Comments" hide-details="true">
                  </v-textarea>
                </v-flex>
                <v-flex pt-4>
                  <v-btn depressed color="#427D2D" @click="addRecommendations()">
                    <span style="color: white;">Add Comments</span>
                  </v-btn>
                </v-flex>
              </v-layout>



              <v-flex xs12 sm4 md4 lg4 pt-2 v-if="mainRole === 'circle' && rescuerdata.challan && rescuerdata.recommendations">
                <v-btn depressed color="success" @click="approveDialog = true">
                  <span>Approve Rescuer</span>
                </v-btn>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 pt-2  v-if="mainRole === 'circle'">
                <v-btn depressed color="error" @click="rejectDialog = true">
                  <span>Reject Rescuer</span>
                </v-btn>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 pt-2 v-if="mainRole === 'circle' && rescuerdata.challan && rescuerdata.recommendations">
                <v-btn depressed color="primary" @click="trainingDialog = true">
                  <span>Move To Training List</span>
                </v-btn>
              </v-flex>


              <!-- <v-flex xs6 pt-lg-5>
                <v-card outlined class="pa-3" v-if="mainRole === 'range'">
                  <v-layout wrap justify-start>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md4>
                        <span class="itemValue">Challan</span>
                      </v-flex>

                      <v-flex xs12 sm6 md8 text-right v-if="rescuerdata.challan">
                        <v-layout wrap>
                          <v-flex xs12>
                            <span v-if="
                              rescuerdata.challan &&
                              rescuerdata.challan.split('.').pop() == 'pdf'
                            ">
                              <a target="_blank" :href="mediaURL + rescuerdata.challan">
                                <v-btn style="border-radius: 14px; border: 0.5px solid #707070" px-5 pt-0 text small
                                  outlined>
                                  View Challan
                                </v-btn>
                              </a>
                            </span>
                            <span v-else>
                              <v-btn @click="(doc = rescuerdata.challan), (docDialog = true)"
                                style="border-radius: 14px; border: 0.5px solid #707070" px-5 pt-0 text small outlined>
                                View Challan
                              </v-btn>
                            </span>
                            &nbsp;
                            <v-btn v-if="! rescuerdata.isChallanVerified === true" color="#005f32" dark small @click="$refs.challan.click()"
                              style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;">
                              EDIT Challan
                            </v-btn>
                            <input v-show="false" id="file" ref="challan" type="file"
                              accept="application/pdf, image/jpeg, image/png, image/jpg"
                              @change="challanUpload($event)" />
                             </v-flex>
                        </v-layout>
                        
                        <v-flex xs6>
                          <v-chip v-if="challan.name" class="ma-2" outlined color="#005f32" text-color="#929292">
                            <v-layout wrap justify-center>
                              <v-flex xs12 text-left pa-2>
                                <span>{{ challan.name.slice(0, 40) }}
                                  <span v-if="challan.name.length > 40">
                                    ...
                                  </span></span>
                              </v-flex>
                            </v-layout>
                          </v-chip>
                        </v-flex>
                        <v-flex xs5 pt-5 text-center v-if="challan.name">
                          <v-btn color="#005f32" dark small @click="uploadChallen">
                            <span style="font-size: 12px; letter-spacing: 0px; opacity: 1;">
                              Save Challan
                            </span>
                          </v-btn>
                        </v-flex>

                      </v-flex>

                      <v-flex xs12 sm6 md8 text-right v-else>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-chip v-if="challan.name" class="ma-2" outlined color="#005f32" text-color="#929292">
                              <v-layout wrap justify-center>
                                <v-flex xs12 text-left pa-2>
                                  <span>{{ challan.name.slice(0, 40) }}
                                    <span v-if="challan.name.length > 40">
                                      ...
                                    </span></span>
                                </v-flex>
                              </v-layout>
                            </v-chip>
                            <v-btn outlined class="rounded-lg" small @click="$refs.challan.click()">
                              <span style="
                                    font-size: 12px;
                                    text-align: end;
                                    letter-spacing: 0px;
                                    opacity: 1;
                                  ">
                            <v-icon small>mdi-upload</v-icon>    UPLOAD FILE
                              </span>
                            </v-btn>
                            <input v-show="false" id="file" ref="challan" type="file"
                              accept="application/pdf, image/jpeg, image/png, image/jpg"
                              @change="challanUpload($event)" />
                          </v-flex>
                        </v-layout>
                        <v-flex xs6 pt-5 text-center>
                          <v-btn color="#005f32" dark small @click="uploadChallen">
                            <span style="font-size: 12px; letter-spacing: 0px; opacity: 1;">
                              Save Challan
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex> -->
              <!-- <v-flex xs3 pt-lg-8 pl-3 mt-6  @click="verifyDialog = true" v-if="(mainRole === 'range') && rescuerdata.isChallanVerified ===false">
               <v-btn class="rounded-lg" color="primary" dark small 
                    style="font-size: 12px; letter-spacing: 0px;">
                    <v-icon small>
                      mdi-check-circle
                                      </v-icon> Verify Challan
                  </v-btn>
              </v-flex> -->

              <v-flex xs12 sm12 md12 lg12 pt-6 text-end>
                <!-- Buttons aligned to the right -->
                <v-layout justify-end>
                  <!-- Verify Challan Button -->
                  <v-btn
                  v-if="!rescuerdata.isChallanVerified"
                  depressed
                  color="primary"
                  @click="verifyDialog = true"
                  class="mr-2"
                >
                  <v-icon small>mdi-check-circle</v-icon>
                  Verify Challan
                </v-btn>

            
                <!-- View File Button -->
                <a
                  v-else
                  target="_blank"
                  :href="mediaURL + rescuerdata.challan"
                  style="text-decoration: none"
                >
                  <v-btn  depressed v-if="mainRole === 'range'"
                  color="#427D2D"   class="mr-2 px-8">
                    <v-icon small>mdi-file"></v-icon>
                  <span style="color:white">View Challan File</span>  
                  </v-btn>
                </a>
                
                  <!-- Reject Rescuer Button -->
                  <v-btn
                    depressed
                    color="error"
                    v-if="mainRole === 'range' && rescuerdata.isChallanVerified == false"
                    @click="rejectDialog = true"
                  >
                    <span>Reject Rescuer</span>
                  </v-btn>
                </v-layout>
              
                <!-- Verify Challan Dialog -->
                <v-dialog v-model="verifyDialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title class="headline">Verify Challan</v-card-title>
                    <v-card-text>
                      <!-- If challan exists, show 'View Challan' -->
                      <div v-if="rescuerdata.challan">
          <p>Challan uploaded successfully:</p>
          <a
            target="_blank"
            :href="mediaURL + rescuerdata.challan"
            style="text-decoration: none"
          >
            <v-btn color="primary" small outlined>
              View Challan
            </v-btn>
          </a>

          <!-- Edit File Button -->
          <v-btn small outlined @click="$refs.challan.click()" class="ml-2">
            <v-icon left small>mdi-file-edit</v-icon>
            Edit File
          </v-btn>
          <input
                          v-show="false"
                          ref="challan"
                          type="file"
                          accept="application/pdf, image/jpeg, image/png, image/jpg"
                          @change="challanUpload"
                        />
                        <v-chip v-if="challan.name" class="ma-2" outlined color="#005f32">
                          {{ challan.name }}
                        </v-chip>
        </div>
                
                      <!-- If challan does not exist, allow upload -->
                      <div v-else>
                        <p>No challan found. Please upload a challan:</p>
                        <v-btn small outlined @click="$refs.challan.click()">
                          <v-icon left small>mdi-upload</v-icon>
                          Upload File
                        </v-btn>
                        <input
                          v-show="false"
                          ref="challan"
                          type="file"
                          accept="application/pdf, image/jpeg, image/png, image/jpg"
                          @change="challanUpload"
                        />
                        <v-chip v-if="challan.name" class="ma-2" outlined color="#005f32">
                          {{ challan.name }}
                        </v-chip>
                      </div>
                    </v-card-text>
                
                    <!-- Buttons for actions -->
                    <v-card-actions>
                      <!-- Save Challan -->
                      <v-btn
                        color="primary"
                        small
                        @click="uploadChallan"
                        :disabled="!challan.name"
                      >
                        Save Challan
                      </v-btn>
                
                      <!-- Verify Challan -->
                      <v-btn
                        color="success"
                        small
                        @click="verifyUser"
                        :disabled="!rescuerdata.challan"
                      >
                        Verify Challan
                      </v-btn>
                
                      <!-- Cancel -->
                      <v-btn color="grey" small @click="verifyDialog = false">
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                
              </v-flex>
              
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6>
                <v-img :src="mediaURL + rescueruserdata.photo" contain height="100%" fill-height></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogadhar" max-width="700">
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6>
                <v-img :src="mediaURL + rescuerdata.adhar" contain height="100%" fill-height></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Approve Rescuer</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Licence Number</span>
                  <v-text-field outlined dense v-model="licencenum" placeholder="Licence Number">
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Category</span>
                  <v-select outlined dense v-model="category" :items="categoryitems">
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Is District Coordinator</span>
                  <v-select outlined dense v-model="districtCoordinator" :items="districtCoordinatoritems">
                  </v-select>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed @click="approveDialog = false"
            class="itemValue">
            Cancel
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed @click="handlesubmit()" class="itemValue">
            <span style="color: #fff">Approve</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- chellan img -->

    
     <!-- chellan img -->

     <v-dialog v-model="docDialog" max-width="700">
      <v-card>
        <v-card-title class="heading">Chellan Image</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-img width="100%" height="100%" :src="mediaURL + doc"></v-img>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
          @click="(doc = null), (docDialog = false)"
            class="itemValue"
          >
            Close
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Reject Rescuer</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Remarks</span>
                  <v-text-field outlined dense v-model="remarks" placeholder="Enter Remarks">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed @click="rejectDialog = false"
            class="itemValue">
            Cancel
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed @click="rejectUser()" class="itemValue">
            <span style="color: #fff">Reject</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- <v-dialog v-model="verifyDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Verify Rescuer</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span> Are you sure you want to verify this rescuer?</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed @click="verifyDialog = false"
            class="itemValue">
            Cancel
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed @click="verifyUser()" class="itemValue">
            <span style="color: #fff">Verify</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->


    <v-dialog v-model="trainingDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Move To Training List</v-card-title>
        <v-card-text>Are you sure you want to move this rescuer to the training
          list?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed @click="trainingDialog = false"
            class="itemValue">
            Cancel
          </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed @click="addTraining()" class="itemValue">
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {

      //verifyDialog: false,
   



      formData: new FormData(),
      documentImg: null,
      verifyDialog:false,
      docs: [],
      doc1: null,
      doc: null,
      documents: "",
      docDialog: false,
      challan: "",
      recommendations: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rescuerdata: [],
      rescueruserdata: [],
      dialog: false,
      dialogadhar: false,
      approveDialog: false,
      rejectDialog: false,
      trainingDialog: false,
      remarks: "",
      licencenum: "",
      categoryitems: ["Official", "Public"],
      districtCoordinator: "",
      category: "",
      districtCoordinatoritems: ["Yes", "No"],
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    mainRole() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    // challanUpload(event) {
    //   this.challan = event.target.files[0];
    // },

    challanUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.challan = file;
      }
    },
    uploadChallan() {
  if (!this.challan) {
    this.msg = "Please upload challan";
    this.showSnackBar = true;
    return false;
  }
  let formData = new FormData();
  formData.append("userid", this.$route.query.id);
  formData.append("challan", this.challan);

  axios({
    method: "POST",
    url: "/rescuer/uploadchallan",
    data: formData,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.data.status === true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;

        // Update challan data reactively
        this.rescuerdata.challan = response.data.challan; // Assuming `challanPath` is returned by the backend
        this.challan = ""; // Clear the selected file
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},
    verifyUser() {
      if (!this.rescuerdata.challan) {
        this.msg = "Please upload and save a challan before verification.";
        this.showSnackBar = true;
        return;
      }

      axios.post(
        "/rescuer/verifyChallan",
        { requestId: this.$route.query.id },
        { headers: { "x-auth-token": localStorage.getItem("token") } }
      )
        .then((response) => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.verifyDialog = false;
            this.getData(); // Refresh data
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },



    formatDate(date) {
      if (!date) return '-';
      const [year, month, day] = date.slice(0, 10).split('-');
      return `${day}-${month}-${year}`;
    },
    openEditDialog(item) {
      this.$router.push({
        path: "/edituser",
        query: {
          id: item._id,
        },
      });
    },
    // verifyUser() {
    //   // if (!this.remarks) {
    //   //   this.msg = "Please provide remarks.";
    //   //   this.showSnackBar = true;
    //   //   return;
    //   // }
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/rescuer/verifyChallan",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     data: {
    //       requestId: this.$route.query.id,
         
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         {
    //           this.msg = response.data.msg;
    //           this.verifyDialog = false;
    //           this.showSnackBar = true;
    //           this.getData();
    //           this.appLoading = false;

    //           //this.$router.push({ path: "/officers/newrescuerslist", });
    //         }
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.appLoading = false;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       (this.ServerError = true), console.log(err);
    //     });
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rescuerdata = response.data.data;
              this.rescueruserdata = response.data.data.user;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    // uploadChallen() {
    //   if (!this.challan) {
    //     this.msg = "Please upload challan";
    //     this.showSnackBar = true;
    //     return false;
    //   }
    //   let formData = new FormData();
    //   formData.append("userid", this.$route.query.id);
    //   formData.append("challan", this.challan);
    //   axios({
    //     method: "POST",
    //     url: "/rescuer/uploadchallan",
    //     data: formData,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.challan = "";
    //         // this.experienceCertificateLastOrg = "";
    //         // this.cv = "";
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    addRecommendations() {

      if (!this.recommendations) {
        this.msg = "Please Provide recommendations.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/division/recommend/rescuer",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,

          recommendations: this.recommendations,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.getData();


            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },


    handlesubmit() {
      if (!this.licencenum) {
        this.msg = "Please Provide Licence Number.";
        this.showSnackBar = true;
        return;
      }
      if (this.districtCoordinator === "Yes") {
        this.districtCoordinatoredit = true;
      }
      if (this.districtCoordinator === "No") {
        this.districtCoordinatoredit = false;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/rescuer/approverescuer/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          rescuerCode: this.licencenum,
          districtCoordinator: this.districtCoordinatoredit,
          idType: this.category,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.approveDialog = false;
              this.showSnackBar = true;
              this.appLoading = false;
              this.$router.push({ path: "/officers/allrescuerlistOfficers", });
              //         setTimeout(() => {
              //   this.$router.push({ name: "/officers/allrescuerlistOfficers" });
              // }, 2000);

            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    updatepassword() {
      if (!this.password) {
        this.msg = "Please enter password to update.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/editpassword",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          password: this.password,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Password Updated Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    addTraining() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/rescuer/movetotraining/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Moved to Training List.";
              this.trainingDialog = false;
              this.showSnackBar = true;
              this.appLoading = false;
              this.$router.push({ path: "/officers/officetraininglist", });
              //         setTimeout(() => {
              //   this.$router.push({ name: "/officers/allrescuerlistOfficers" });
              // }, 2000);
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    rejectUser() {
      if (!this.remarks) {
        this.msg = "Please provide remarks.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/rescuer/rejectrescuer/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          reason: this.remarks,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.rejectDialog = false;
              this.showSnackBar = true;
              this.appLoading = false;
              // this.$router.push({ name: "newrescuerslist" });
              this.$router.push({ path: "/officers/newrescuerslist", });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}

.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>